/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/1a142ec2652f2d06-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_bcf733';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/dba81c1208da12ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_bcf733';src: local("Arial");ascent-override: 73.11%;descent-override: 20.28%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_bcf733 {font-family: '__Source_Code_Pro_bcf733', '__Source_Code_Pro_Fallback_bcf733';font-style: normal
}.__variable_bcf733 {--font-source-code-pro: '__Source_Code_Pro_bcf733', '__Source_Code_Pro_Fallback_bcf733'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/25531967d1c9d61c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7cb88e19c057d1b9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/90e03eaa85e5c818-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/baaf16ecc4ebd660-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/568bde209bd1b223-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f28acc839182a07b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bbc011d2ce4bf49a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9122b12ce32e89db-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6e4807027d1565dd-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bd034d653d97a806-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fce6dcb5e72525b3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Play_976028';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d9c5807611411772-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Play_Fallback_976028';src: local("Arial");ascent-override: 92.83%;descent-override: 21.79%;line-gap-override: 0.00%;size-adjust: 100.94%
}.__className_976028 {font-family: '__Play_976028', '__Play_Fallback_976028';font-style: normal
}.__variable_976028 {--font-play: '__Play_976028', '__Play_Fallback_976028'
}

.Header_header__TsQ2I {
}

.Header_mainSection__2Ymcf {
    padding-bottom: 0; /*var(--mantine-spacing-xs);*/ /* matches header padding-top */
}

.Header_user___i2t7 {
    color: var(--mantine-color-black); /* var(--mantine-spacing-xs) var(--mantine-spacing-sm); */
}

[data-mantine-color-scheme='dark'] .Header_user___i2t7 {
    color: var(--mantine-color-dark-0);
}

.Header_user___i2t7 {
    padding: 0;
    border-radius: var(--mantine-radius-xl);
    transition: background-color 100ms ease;
}

.Header_user___i2t7:hover {
         background-color: var(--mantine-color-white);
     }

[data-mantine-color-scheme='dark'] .Header_user___i2t7:hover {
         background-color: var(--mantine-color-dark-8);
}

@media (max-width: 36em) {

.Header_user___i2t7 {
        display: none
}
    }

.Header_userActive__x0CD2 {
    background-color: var(--mantine-color-white);
}

[data-mantine-color-scheme='dark'] .Header_userActive__x0CD2 {
    background-color: var(--mantine-color-dark-8);
}

.Header_avatarDark__nGDXL img {
    /*filter: grayscale(100%);*/
}

.Header_avatarLight__QwWzI img {
    /*filter: grayscale(100%);*/
}

.Header_iconAvatarDark__B2r_3 {
    /*filter: grayscale(100%);*/
}

.Header_iconAvatarLight__jITFL {
    /*filter: grayscale(100%);*/
}

.Footer_footer__4ORg_ {
    margin: 0 auto;
}

.Footer_inner__I2M2D {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

