.header {
}

.mainSection {
    padding-bottom: 0; /*var(--mantine-spacing-xs);*/ /* matches header padding-top */
}

.user {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    padding: 0; /* var(--mantine-spacing-xs) var(--mantine-spacing-sm); */
    border-radius: var(--mantine-radius-xl);
    transition: background-color 100ms ease;

    &:hover {
         background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
     }

    @media (max-width: $mantine-breakpoint-xs) {
        display: none;
    }
}

.userActive {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
}

.avatarDark img {
    /*filter: grayscale(100%);*/
}

.avatarLight img {
    /*filter: grayscale(100%);*/
}

.iconAvatarDark {
    /*filter: grayscale(100%);*/
}

.iconAvatarLight {
    /*filter: grayscale(100%);*/
}
